.hero {
  height: 100vh;
  width: 100%;
  background: url('src/assets/banner.png') no-repeat center center/cover;
}

.banner {
  width: 100%;
  height: 400px;
  background: url('https://hips.hearstapps.com/hmg-prod/images/young-man-running-outdoors-in-morning-royalty-free-image-1717693611.jpg')
    no-repeat center center/cover;
}
